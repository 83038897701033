<template>
  <div
    class="tw-flex tw-w-full tw-flex-col tw-bg-white tw-p-4 tw-text-start tw-duration-300 hover:tw-scale-[0.99] hover:tw-shadow-md"
    style="border-radius: var(--rounded)"
  >
    <library-image
      :src="branch.photo"
      :quality="60"
      style="border-radius: var(--rounded-sm)"
    />
    <div
      class="tw-flex tw-h-full tw-flex-col tw-items-start tw-justify-between"
    >
      <h4 class="tw-mt-5">{{ branch.title }}</h4>
      <div
        v-if="branch.primary_mail"
        class="tw-flex tw-flex-row tw-items-center tw-gap-2 tw-pt-2"
      >
        <span>
          <svg
            width="17"
            height="13"
            viewBox="0 0 17 13"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M1.87219 0.5H15.1222C16.3722 0.5 17.0022 1.09 17.0022 2.29V10.71C17.0022 11.9 16.3722 12.5 15.1222 12.5H1.87219C0.622187 12.5 -0.0078125 11.9 -0.0078125 10.71V2.29C-0.0078125 1.09 0.622187 0.5 1.87219 0.5ZM8.49219 9.1L15.2322 3.57C15.4722 3.37 15.6622 2.91 15.3622 2.5C15.0722 2.09 14.5422 2.08 14.1922 2.33L8.49219 6.19L2.80219 2.33C2.45219 2.08 1.92219 2.09 1.63219 2.5C1.33219 2.91 1.52219 3.37 1.76219 3.57L8.49219 9.1Z"
              fill="black"
            />
          </svg>
        </span>
        <nuxt-link
          :external="true"
          :to="`mailto: ${branch.primary_mail}`"
          class="n-link tw-break-all !tw-font-medium"
          style="font-family: unset !important"
          >{{ branch.primary_mail }}</nuxt-link
        >
      </div>
      <div
        v-if="branch.secondary_mail"
        class="tw-flex tw-flex-row tw-items-center tw-gap-2 tw-pt-2"
      >
        <span>
          <svg
            width="17"
            height="13"
            viewBox="0 0 17 13"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M1.87219 0.5H15.1222C16.3722 0.5 17.0022 1.09 17.0022 2.29V10.71C17.0022 11.9 16.3722 12.5 15.1222 12.5H1.87219C0.622187 12.5 -0.0078125 11.9 -0.0078125 10.71V2.29C-0.0078125 1.09 0.622187 0.5 1.87219 0.5ZM8.49219 9.1L15.2322 3.57C15.4722 3.37 15.6622 2.91 15.3622 2.5C15.0722 2.09 14.5422 2.08 14.1922 2.33L8.49219 6.19L2.80219 2.33C2.45219 2.08 1.92219 2.09 1.63219 2.5C1.33219 2.91 1.52219 3.37 1.76219 3.57L8.49219 9.1Z"
              fill="black"
            />
          </svg>
        </span>
        <nuxt-link
          :external="true"
          :to="`mailto: ${branch.secondary_mail}`"
          class="n-link tw-break-all !tw-font-medium"
          style="font-family: unset !important"
          >{{ branch.secondary_mail }}</nuxt-link
        >
      </div>
      <div
        v-if="branch.phone"
        class="tw-flex tw-flex-row tw-items-center tw-gap-2 tw-pt-2"
      >
        <span>
          <svg
            width="17"
            height="16"
            viewBox="0 0 17 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M16.1156 12.1782C15.9763 13.2368 15.4564 14.2085 14.6531 14.9119C13.8497 15.6152 12.8177 16.002 11.75 16.0001C5.54688 16.0001 0.500007 10.9532 0.500007 4.75009C0.498093 3.68234 0.884919 2.65042 1.58824 1.84704C2.29155 1.04366 3.26326 0.523763 4.32188 0.384461C4.58958 0.351774 4.86067 0.406541 5.09468 0.540585C5.3287 0.674629 5.51309 0.880762 5.62032 1.12821L7.27032 4.8118V4.82118C7.35242 5.0106 7.38633 5.2174 7.36901 5.42311C7.3517 5.62883 7.2837 5.82706 7.1711 6.00009C7.15704 6.02118 7.14219 6.04071 7.12657 6.06024L5.50001 7.98837C6.08516 9.17743 7.32891 10.4102 8.5336 10.997L10.4352 9.37899C10.4538 9.36328 10.4734 9.34867 10.4938 9.33524C10.6666 9.21993 10.8655 9.14954 11.0725 9.13045C11.2794 9.11135 11.4878 9.14415 11.6789 9.22587L11.6891 9.23056L15.3695 10.8798C15.6174 10.9866 15.8241 11.1709 15.9585 11.4049C16.093 11.6389 16.1481 11.9102 16.1156 12.1782Z"
              fill="black"
            />
          </svg>
        </span>
        <nuxt-link
          :to="`tel:${branch.phone}`"
          :external="true"
          class="n-link !tw-font-medium"
          style="font-family: unset !important"
          >{{ branch.phone }}</nuxt-link
        >
      </div>
      <div
        v-if="branch.address_lines"
        class="tw-flex tw-flex-row tw-items-start tw-gap-2 tw-pt-2"
      >
        <span class="tw-mt-1">
          <svg
            width="15"
            height="18"
            viewBox="0 0 15 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M8.945 17.18C11.1238 15.2625 15 11.345 15 7.75C15 5.76088 14.2098 3.85322 12.8033 2.4467C11.3968 1.04018 9.48912 0.25 7.5 0.25C5.51088 0.25 3.60322 1.04018 2.1967 2.4467C0.790176 3.85322 2.96403e-08 5.76088 0 7.75C0 11.345 3.875 15.2625 6.055 17.18C6.45264 17.5349 6.967 17.7311 7.5 17.7311C8.033 17.7311 8.54736 17.5349 8.945 17.18ZM5 7.75C5 7.08696 5.26339 6.45107 5.73223 5.98223C6.20107 5.51339 6.83696 5.25 7.5 5.25C8.16304 5.25 8.79893 5.51339 9.26777 5.98223C9.73661 6.45107 10 7.08696 10 7.75C10 8.41304 9.73661 9.04893 9.26777 9.51777C8.79893 9.98661 8.16304 10.25 7.5 10.25C6.83696 10.25 6.20107 9.98661 5.73223 9.51777C5.26339 9.04893 5 8.41304 5 7.75Z"
              fill="black"
            />
          </svg>
        </span>
        <div class="tw-flex tw-h-[120px] tw-flex-col tw-gap-1">
          <div v-html="branch.address_lines" class="tw-line-clamp-5"></div>
        </div>
      </div>

      <nuxt-link
        class="tw-mt-5"
        :to="branch.button_link"
        :class="`n-${branch.button_type}`"
        >{{ branch.button_label }}</nuxt-link
      >
    </div>
  </div>
</template>

<script lang="ts">
export default defineNuxtComponent({
  name: 'BranchCard',

  props: {
    branch: {
      type: Object,
      required: true,
    },
  },
});
</script>
