<template>
  <nuxt-link :to="`/blogs/${blog.url}`" :external="true" class="n-blog-card">
    <library-image
      :src="blog.image"
      :title="blog.title"
      :alt="blog.title"
      :design-width="455"
      :design-height="316"
    />
    <h4>{{ blog.title }}</h4>
    <p>{{ blog.description }}</p>
  </nuxt-link>
</template>

<script lang="ts">
import type { PropType } from 'vue';
import type { BlogItemComponent } from '~/units/blogs/types';

export default defineNuxtComponent({
  name: 'Theme4BlogCard',

  props: {
    blog: {
      required: true,
      type: Object as PropType<BlogItemComponent>,
    },

    index: {
      required: true,
      type: Number,
    },
  },
});
</script>
